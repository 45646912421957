export const placements = [
    {
        id: 'board_1',
        invCode: 'no-e24-wph-front_board_1',
        subtype: 'netboard',
        sizes: [
            [320, 250],
            [300, 250],
            [320, 400],
            [1, 1],
            [5, 250],
            [5, 400],
        ],
        allowedFormats: ['native', 'banner'],
        finnBlink: {
            enabled: true,
            position: 'mobile_board_1',
        },
        keywords: {
            'no-sno-adformat': ['board_1'],
        },
        safeframe: true,
        videoEnabled: true,
    },
    {
        id: 'board_2',
        invCode: 'no-e24-wph-front_board_2',
        subtype: 'netboard',
        sizes: [
            [320, 250],
            [300, 250],
            [320, 400],
            [1, 1],
            [5, 250],
            [5, 400],
        ],
        allowedFormats: ['native', 'banner'],
        finnBlink: {
            enabled: true,
            position: 'mobile_board_2',
        },
        keywords: {
            'no-sno-adformat': ['board_2'],
        },
        safeframe: true,
        videoEnabled: true,
    },
    {
        id: 'board_3',
        invCode: 'no-e24-wph-front_board_3',
        subtype: 'netboard',
        sizes: [
            [320, 250],
            [300, 250],
            [320, 400],
            [1, 1],
            [5, 250],
            [5, 400],
        ],
        allowedFormats: ['native', 'banner'],
        finnBlink: {
            enabled: true,
            position: 'mobile_board_3',
        },
        keywords: {
            'no-sno-adformat': ['board_3'],
        },
        safeframe: true,
        videoEnabled: true,
    },
    {
        id: 'board_4',
        invCode: 'no-e24-wph-front_board_4',
        subtype: 'netboard',
        sizes: [
            [320, 250],
            [300, 250],
            [320, 400],
            [1, 1],
            [5, 250],
            [5, 400],
        ],
        allowedFormats: ['native', 'banner'],
        finnBlink: {
            enabled: true,
            position: 'mobile_board_4',
        },
        keywords: {
            'no-sno-adformat': ['board_4'],
        },
        safeframe: true,
        videoEnabled: true,
    },
    {
        id: 'board_backfill_1',
        invCode: 'no-e24-wph-front_board_backfill_1',
        subtype: 'netboard',
        sizes: [
            [320, 250],
            [300, 250],
            [320, 200],
            [320, 400],
            [1, 1],
            [5, 250],
            [5, 400],
        ],
        allowedFormats: ['native', 'banner'],
        finnBlink: {
            enabled: true,
            position: 'hayabusa_mobile_1',
            renderOnlyWithAdBlock: true,
        },
        keywords: {
            'no-sno-adformat': ['board_backfill_1'],
        },
        safeframe: true,
        videoEnabled: true,
    },
    {
        id: 'board_backfill_2',
        invCode: 'no-e24-wph-front_board_backfill_2',
        subtype: 'netboard',
        sizes: [
            [320, 250],
            [300, 250],
            [320, 200],
            [320, 400],
            [1, 1],
            [3, 3],
            [1920, 1080],
            [5, 250],
            [5, 400],
        ],
        allowedFormats: ['native', 'banner'],
        finnBlink: {
            enabled: true,
            position: 'hayabusa_mobile_2',
            renderOnlyWithAdBlock: true,
        },
        format: ['board_backfill_2', 'board_fullscreen'],
        keywords: {
            'no-sno-adformat': ['board_backfill_2', 'board_fullscreen'],
        },
        safeframe: true,
        videoEnabled: true,
    },
    {
        id: 'board_backfill_3',
        invCode: 'no-e24-wph-front_board_backfill_3',
        subtype: 'netboard',
        sizes: [
            [320, 250],
            [300, 250],
            [320, 400],
            [1, 1],
            [5, 250],
            [5, 400],
        ],
        allowedFormats: ['native', 'banner'],
        finnBlink: {
            enabled: true,
            position: 'hayabusa_mobile_3',
            renderOnlyWithAdBlock: true,
        },
        keywords: {
            'no-sno-adformat': ['board_backfill_3'],
        },
        safeframe: true,
        videoEnabled: true,
    },
    {
        id: 'board_backfill_4',
        invCode: 'no-e24-wph-front_board_backfill_4',
        subtype: 'netboard',
        sizes: [
            [320, 250],
            [300, 250],
            [320, 400],
            [1, 1],
            [5, 250],
            [5, 400],
        ],
        allowedFormats: ['native', 'banner'],
        finnBlink: {
            enabled: true,
            position: 'hayabusa_mobile_4',
            renderOnlyWithAdBlock: true,
        },
        keywords: {
            'no-sno-adformat': ['board_backfill_4'],
        },
        safeframe: true,
        videoEnabled: true,
    },
    {
        id: 'board_backfill_5',
        invCode: 'no-e24-wph-front_board_backfill_5',
        subtype: 'netboard',
        sizes: [
            [320, 250],
            [300, 250],
            [320, 400],
            [1, 1],
            [5, 250],
            [5, 400],
        ],
        allowedFormats: ['native', 'banner'],
        keywords: {
            'no-sno-adformat': ['board_backfill_5'],
        },
        safeframe: true,
        videoEnabled: true,
    },
    {
        id: 'board_backfill_6',
        invCode: 'no-e24-wph-front_board_backfill_6',
        subtype: 'netboard',
        sizes: [
            [320, 250],
            [300, 250],
            [320, 400],
            [1, 1],
            [5, 250],
            [5, 400],
        ],
        allowedFormats: ['native', 'banner'],
        finnBlink: {
            enabled: true,
            position: 'hayabusa_mobile_5',
            renderOnlyWithAdBlock: true,
        },
        keywords: {
            'no-sno-adformat': ['board_backfill_6'],
        },
        safeframe: true,
        videoEnabled: true,
    },
];
