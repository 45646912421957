import type { EventInputObject, EventInputTarget } from '@e24/mbbs';
import {
    DATA_CONTENT_ID,
    DATA_EXPERIMENT_TEST_ID,
    DATA_EXPERIMENT_VARIANT_ID,
    DATA_PAYWALL,
    DATA_SLUG,
    DATA_TITLE,
} from '@innhold/core/tracking/constants';
import { getPulse } from '@innhold/core/tracking/pulse';

const getBaseEventInput = (element: Element) => {
    const id = element.getAttribute(DATA_CONTENT_ID);
    const name = element.getAttribute(DATA_TITLE);
    const url = element.getAttribute(DATA_SLUG);
    const withPaywall = element.getAttribute(DATA_PAYWALL) === 'true';

    return {
        object: {
            id,
            name,
            type: 'Teaser',
            placement: {
                curateContext: 'frontpage',
            },
        } as EventInputObject,
        target: {
            id,
            name,
            type: 'Article',
            article_access_level: withPaywall ? 'Paid' : 'Free',
            category: 'News',
            url,
        } as EventInputTarget,
    };
};

const isExperiment = (element: Element) => {
    return [DATA_EXPERIMENT_VARIANT_ID, DATA_EXPERIMENT_TEST_ID].every(
        (experimentAttribute) => element.hasAttribute(experimentAttribute)
    );
};

const getExperimentsData = (element: Element) => {
    const id = element.getAttribute(DATA_CONTENT_ID);
    return [
        {
            '@id': `sdrn:curate:experiment:teasers-${id}`,
            variant: element.getAttribute(DATA_EXPERIMENT_VARIANT_ID),
            name: `Teaser experiment for article ${id}`,
            custom: {
                testId: element.getAttribute(DATA_EXPERIMENT_TEST_ID),
            },
            platform: 'curate',
        },
    ];
};

export default async () => {
    const pulse = await getPulse();

    const intersectionObserver = new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const input = getBaseEventInput(entry.target);

                    if (isExperiment(entry.target)) {
                        input['experiments'] = getExperimentsData(entry.target);
                    }

                    pulse.trackElementView(input);
                    intersectionObserver.unobserve(entry.target);
                }
            });
        },
        {
            rootMargin: '0px',
            threshold: 0.5,
        }
    );

    const setupTeaserTracking = (teaser: Element) => {
        teaser.addEventListener('click', () => {
            const input = getBaseEventInput(teaser);

            if (isExperiment(teaser)) {
                input['experiments'] = getExperimentsData(teaser);
            }

            pulse.trackElementClick(input);
        });
        intersectionObserver.observe(teaser);
    };

    const contentMutationObserver = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            mutation.addedNodes.forEach((node: Element) => {
                if (node.nodeType === Node.ELEMENT_NODE) {
                    const isTeaserElement = node.matches(`a[${DATA_CONTENT_ID}]`);

                    if (isTeaserElement) {
                        setupTeaserTracking(node);
                    }
                }
            });
        });
    });

    const container = document.querySelector('.grid-container');
    const staticTeasers = document.querySelectorAll(`a[${DATA_CONTENT_ID}]`);

    staticTeasers.forEach(setupTeaserTracking);
    contentMutationObserver.observe(container, { subtree: true, childList: true });
};
