export function timeoutPromiseWithReturnValue(promise: Promise<unknown>, ms: number) {
    return Promise.race([
        promise,
        new Promise((_resolve, reject) => {
            const timeout = setTimeout(
                () => reject(new Error(`Promise timeout after ${ms}ms`)),
                ms
            );
            const _clearTimeout = () => clearTimeout(timeout);
            promise.then(_clearTimeout, _clearTimeout).catch(() => {});
        }),
    ]);
}
