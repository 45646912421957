export const placements = [
    {
        id: 'topboard',
        invCode: 'no-e24-wde-front_topboard',
        subtype: 'topboard',
        sizes: [
            [980, 150],
            [980, 250],
            [980, 300],
            [1010, 150],
            [1010, 300],
        ],
        allowedFormats: ['banner'],
        group: 'horseshoe',
        keywords: {
            'no-sno-adformat': ['topboard'],
        },
        safeframe: true,
    },
    {
        id: 'skyscraperleft_1',
        invCode: 'no-e24-wde-front_skyscraperleft_1',
        subtype: 'skyscraperleft_1',
        sizes: [
            [180, 501],
            [180, 701],
        ],
        allowedFormats: ['banner'],
        group: 'horseshoe',
        keywords: {
            'no-sno-adformat': ['skyscraperleft_1'],
        },
        lazyload: false,
        safeframe: true,
    },
    {
        id: 'skyscraperright_1',
        invCode: 'no-e24-wde-front_skyscraperright_1',
        subtype: 'skyscraperright_1',
        sizes: [
            [180, 500],
            [180, 700],
            [160, 600],
            [300, 500],
            [300, 600],
        ],
        allowedFormats: ['banner'],
        group: 'horseshoe',
        keywords: {
            'no-sno-adformat': ['skyscraperright_1'],
        },
        safeframe: true,
    },
    {
        id: 'skyscraperright_2',
        invCode: 'no-e24-wde-front_skyscraperright_2',
        subtype: 'skyscraperright_2',
        sizes: [
            [180, 500],
            [180, 700],
            [160, 600],
            [300, 500],
            [300, 600],
        ],
        allowedFormats: ['banner'],
        group: 'smartboard',
        keywords: {
            'no-sno-adformat': ['skyscraperright_2'],
        },
        safeframe: true,
    },
    {
        id: 'netboard_1',
        invCode: 'no-e24-wde-front_netboard_1',
        subtype: 'netboard',
        sizes: [
            [580, 400],
            [580, 500],
            [1, 1],
        ],
        allowedFormats: ['banner', 'native'],
        finnBlink: {
            enabled: true,
            position: 'desktop_netboard_1',
        },
        group: 'netboard',
        keywords: {
            'no-sno-adformat': ['netboard_1'],
        },
        safeframe: true,
        videoEnabled: true,
    },
    {
        id: 'netboard_2',
        invCode: 'no-e24-wde-front_netboard_2',
        subtype: 'netboard',
        sizes: [
            [580, 400],
            [580, 500],
            [1, 1],
        ],
        allowedFormats: ['banner', 'native'],
        finnBlink: {
            enabled: true,
            position: 'desktop_netboard_2',
        },
        group: 'netboard',
        keywords: {
            'no-sno-adformat': ['netboard_2'],
        },
        safeframe: true,
        videoEnabled: true,
    },
    {
        id: 'netboard_backfill_1',
        invCode: 'no-e24-wde-front_netboard_backfill_1',
        subtype: 'netboard',
        sizes: [
            [580, 400],
            [580, 500],
            [1, 1],
        ],
        allowedFormats: ['banner', 'native'],
        finnBlink: {
            enabled: true,
            position: 'hayabusa_desktop_1',
            renderOnlyWithAdBlock: true,
        },
        group: 'netboard',
        keywords: {
            'no-sno-adformat': ['netboard_backfill_1'],
        },
        safeframe: true,
        videoEnabled: true,
    },
    {
        id: 'netboard_backfill_2',
        invCode: 'no-e24-wde-front_netboard_backfill_2',
        subtype: 'netboard',
        sizes: [
            [580, 400],
            [580, 500],
            [1, 1],
        ],
        allowedFormats: ['banner', 'native'],
        finnBlink: {
            enabled: true,
            position: 'hayabusa_desktop_2',
            renderOnlyWithAdBlock: true,
        },
        group: 'netboard',
        keywords: {
            'no-sno-adformat': ['netboard_backfill_2'],
        },
        safeframe: true,
        videoEnabled: true,
    },
    {
        id: 'netboard_backfill_3',
        invCode: 'no-e24-wde-front_netboard_backfill_3',
        subtype: 'netboard',
        sizes: [
            [580, 400],
            [580, 500],
            [1, 1],
        ],
        allowedFormats: ['banner', 'native'],
        finnBlink: {
            enabled: true,
            position: 'hayabusa_desktop_3',
            renderOnlyWithAdBlock: true,
        },
        group: 'netboard',
        keywords: {
            'no-sno-adformat': ['netboard_backfill_3'],
        },
        safeframe: true,
        videoEnabled: true,
    },
    {
        id: 'netboard_backfill_4',
        invCode: 'no-e24-wde-front_netboard_backfill_4',
        subtype: 'netboard',
        sizes: [
            [580, 400],
            [580, 500],
            [1, 1],
        ],
        allowedFormats: ['banner', 'native'],
        finnBlink: {
            enabled: true,
            position: 'desktop_forside_netboard_backfill_4',
        },
        group: 'netboard',
        keywords: {
            'no-sno-adformat': ['netboard_backfill_4'],
        },
        safeframe: true,
        videoEnabled: true,
    },
    {
        id: 'bottomboard',
        invCode: 'no-e24-wde-front_bottomboard',
        subtype: 'bottomboard',
        sizes: [
            [980, 300],
            [980, 500],
            [980, 600],
        ],
        allowedFormats: ['banner', 'video'],
        group: 'smartboard',
        keywords: {
            'no-sno-adformat': ['bottomboard'],
        },
        safeframe: true,
    },
    {
        id: 'brandboard',
        invCode: 'no-e24-wde-front_brandboard',
        subtype: 'brandboard',
        sizes: [
            [980, 600],
            [980, 500],
            [980, 300],
            [980, 150],
        ],
        allowedFormats: ['banner', 'native'],
        group: 'smartboard',
        keywords: {
            'no-sno-adformat': ['brandboard'],
        },
        safeframe: true,
    },
    {
        id: 'brandboard_backfill_1',
        invCode: 'no-e24-wde-front_brandboard_backfill_1',
        subtype: 'brandboard',
        sizes: [[980, 300]],
        allowedFormats: ['banner'],
        group: 'netboard',
        keywords: {
            'no-sno-adformat': ['brandboard_backfill_1'],
        },
        safeframe: true,
        videoEnabled: true,
    },
    {
        id: 'fullscreen',
        invCode: 'no-e24-wde-front_fullscreen',
        subtype: 'fullscreen',
        sizes: [[1920, 1080]],
        allowedFormats: ['banner', 'video'],
        format: ['fullscreen'],
        group: 'smartboard',
        keywords: {
            'no-sno-adformat': ['fullscreen'],
        },
        safeframe: true,
    },
    {
        id: 'wallpaper',
        invCode: 'no-e24-wde-front_wallpaper',
        subtype: 'wallpaper',
        sizes: [[2, 2]],
        allowedFormats: ['banner'],
        group: 'horseshoe',
        keywords: {
            'no-sno-adformat': ['wallpaper'],
        },
        lazyload: false,
        safeframe: true,
    },
];
