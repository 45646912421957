import { log } from '@innhold/core/logger';
import { timeoutPromiseWithReturnValue } from '@innhold/lib/timers/withReturnValue';

function getPermissionsOrTimeout() {
    return timeoutPromiseWithReturnValue(getPermissions(), 500);
}

async function getPermissions() {
    if (window.lbmpPermissions !== undefined) {
        return window.lbmpPermissions;
    }

    return new Promise((resolve) => {
        window.addEventListener(
            'lbmp-updated',
            () => resolve(window.lbmpPermissions ?? null),
            { once: true }
        );
    });
}

export async function hasGeoConsent() {
    try {
        // @todo temporary hack to fix 500ms latency on ads - see issue #2514
        if (!window.lbmpPermissions) {
            return true;
        }

        const permissions = await getPermissionsOrTimeout();

        if (!permissions) {
            return true;
        }

        if (Array.isArray(permissions)) {
            return permissions.some(
                ({ data, purpose }) =>
                    purpose === 'sdrn:e24:processingpurpose:targeted_advertising' &&
                    data.includes('sdrn:schibsted-media:datacategory:geolocation')
            );
        }
    } catch (err) {
        log.error('Error! Failed to get permissions!', err);
        return true;
    }
}
